.hamburger-container {
    position: fixed;
    top: 0;
    right: -13px;
    font-size: 2rem;
    z-index: 100;
    #hamburger-menu-logo {
        // height: 30px;
        height: 3rem;
    }
    .hamburger-links {
        ul {
            text-align: center;
            padding: 0;
            li {
                list-style: none;
                padding: .01em 1em;
                margin-bottom: .4em;
            }
        }
    }
}