// landing page //
.App {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: var(--grey);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  min-height: calc(var(--vh) * 100);
 
  #out {
    font-size: 3.5em;
    font-weight: 900;
    margin: 0;
    align-self: end;
  }
  #btns {
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    justify-content: space-between;
  }
  #enter-btn, #order-here {
    display: inline-block;
    align-self: end;
    font-size: 3.5rem;
    letter-spacing: .1em;
    font-weight: 900;
    height: 2em;
    width: 9em;
    position: relative;
    padding: 1rem 0 0;
    margin: 0 auto;
    font-family: 'Times New Roman', Times, serif;
    background-color: #00000000;
    border: none;
    color: inherit;
    outline: none;
  }
  #order-here {
    display: block;
    position: relative;
    margin: 1em auto 2em;
    height: fit-content;
    width: 10em;
    font-size: 2.5rem;
    border: 1px solid var(--grey);
    opacity: .8;
    padding: 10px;
  }
  #no-route-menu {
    display: inline-block;
    align-self: end;
  }
  #no-route-menu {
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 70%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
      margin-bottom: 20px;
    }
    a {
      padding: 0;
      margin: 0 5px;
    }
  }
}
// about page //
.about-page__container {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: var(--grey);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  min-height: 100vh;
  min-width: 100vw;
}


@media (min-width: 900px) {
  .App {
    h3 {
      font-size: 2.5rem;
    }
    #enter-btn {
      font-size: 2rem;
    }

  }
}
