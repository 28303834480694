.about-telurian {
    padding: 0 0 0 1em;
    h3 {
        font-size: 2.2rem;
        text-align: center;
        font-variant: small-caps;
    }
    p {
        text-align: justify;
        font-size: 1.7rem;
        text-indent: 1em;
    }
    a {
        border: 1px solid #00000020;
        border-radius: 2px;
        padding: 2px 20px;
        background-color: #00000040;
        color: #ffc;
        font-size: .7rem;
    }
}
