    .landing-page__title {
        h1 {
            font-weight: 200;
            font-size: 4em;
            margin: .5em 0 0;
            letter-spacing: .2em;
            cursor: pointer;
        }
        h2 {
            font-size: 1.1rem;
            margin: 0 0 2em;
            letter-spacing: .55em;
        }
    }
