.background {
    position: fixed;
    z-index: -100;
    height: 100%;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}