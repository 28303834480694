.item {
    display: flex;
    flex-wrap: wrap;
    padding: 3em 2em;
    color: var(--grey);
    .item-photo {
        position: relative;
        align-self: start;
        display: flex;
        flex-direction: column;
        width: 100%;
        .sold-out {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 100;
            background: #00000090;
            &:before {
                content: 'SOLD OUT';
                display: inline-block;
                font: {
                    size: 40px;
                    family: 'Abw';
                }
                color: #fff;
                transform: rotate(315deg);
                position: absolute;
                top: 37%;
                left: -27%;
                width: 150%;
                padding: 10px 0;
                @media (min-width: 768px) {
                    font-size: 45px;
                }
            }
        }
        img {
            position: relative;
            width: 100%;
            margin: 0 auto;
        }
        button {
            margin: .5em 0;
            font-size: 1rem;
            width: auto;
            color: var(--grey);
            border: none;
            background-color: #00000000;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 900;
            text-shadow: 1.5px 1.5px 1px white;
        }
        .paypal {
            position: relative;
            margin: 0 auto;
            width: 100%;
            select {
                width: 60%;
                height: 30px;
                font-size: 1.1rem;
                margin-top: .7em;
            }
            input {
                width: 100%;
            }
            .buy-now {
                border: 1px solid var(--grey);
                border-radius: 5px;
                margin-top: .4em;
                padding: 0 1em;
                width: 60%;
                opacity: .8;
            }
        }
    }
    .item-description {
        align-self: start;
        padding-left: 2em;
        width: 100%;
        h3 {
            margin: 10px 0 0 0;
            padding-bottom: 1em;
            font-size: 1.4rem;
            text-align: center;
        }
        p {
            text-align: left;
            font-size: 1.4rem;
            font-weight: 400;
        }
        
        p:last-child {
            margin-top: 10px;
            font-weight: 700;
            font-size: .8rem;
        }
    }
}

@media (min-width: 540px) {
    .item {
        .item-photo {
            width: 35%;
            .sold-out {
                &:before {
                    font-size: 30px;
                }
            }
            .paypal {
                select {
                    width: 100%;
                }
                .buy-now {
                    width: 100%;
                }
            }
        }
        .item-description {
            width: 65%;
        }
    }
}

@media (min-width: 900px) {
    .item {
        .item-photo {
            width: 27%;
            .paypal {
                width: 100%;
                select {
                    width: 100%;
                    font-size: .8rem;
                    color: var(--grey);
                }
            .buy-now {
                margin-top: 1em;
                width: 60%;
            }
            }
        }
        .item-description {
            width: 73%;
            h3 {
                font-size: 1.2rem;
            }
            p {
                font-size: 1.1rem;
            }
        }
    }
}