.opus {
    font-size: 1.6rem;
    list-style: none;
    margin: 0;
    padding: 0;
    p {
        font-variant: small-caps;
        font-size: 2.2rem;
        letter-spacing: .1em;
        padding: 1em 0;
        font-weight: 900;
    }
    li {
        padding: .2em 0;
    }
}
