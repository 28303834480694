@font-face {
  font-family: "Anor"; 
  src: url("./fonts/ARCHIAN/Archi10.TTF") format("truetype");
}
@font-face {
  font-family: 'Abw';
  src: url("./fonts/ARCHIAN/Archi18.TTF") format("truetype");
}

$grey: #676767;
:root {
  --grey: #{$grey};
  font-size: calc( 6px + 1vw );
}
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}
